// Librairies
import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
// CSS
import "./Footer.scss";
import * as couleurs from "../Couleurs"
import { Facebook, X, Youtube, Instagram } from "../SVG/Svg";

class Footer extends React.Component {

	render() {

		return (
			<div>
				<footer className="container-fluid footer">
					<div className="socialNetwork">
						<a href="https://fr-fr.facebook.com/LibrairieCalvin" target="_blank" className="elem"><Facebook width={35} /></a>
						<a href="https://twitter.com/libjeancalvin" target="_blank" className="elem"><X width={35} /></a>
						<a href="https://www.youtube.com/@librairiejeancalvin2106/videos" target="_blank" className="elem"><Youtube width={35} /></a>
						<a href="/" target="_blank" className="elem"><Instagram width={35} /></a>
					</div>
					<div className="menu">
						<div className="elem" style={{color: couleurs.BLEU, fontSize: "1.4em"}}>
							<div><Link to="/nos-librairies/ales" style={{fontSize: "1rem", fontWeight: "500"}}>04 66 86 16 61 | contact@librairiejeancalvin.fr</Link></div>
							<a style={{fontSize: "1rem"}} target="_blank" href="https://storage.jeancalvin.fr/cgv_ljc.pdf">Mensions légales</a> | 
							<a style={{fontSize: "1rem"}} target="_blank" href="https://storage.jeancalvin.fr/cgv_ljc.pdf">Protection des données</a> | 
							<a style={{fontSize: "1rem"}} target="_blank" href="https://storage.jeancalvin.fr/cgv_ljc.pdf">Conditions générales de vente</a>
						</div>
						{/* <Link to="/" className="elem">Contact </Link> */}
						{/* <Link to="/" className="elem"> Partenaire</Link> */}
					</div>
				</footer>
			</div>
		);
	}
}

export default withTranslation()(Footer);
