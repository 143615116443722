import { User } from "oidc-client-ts"


export const URL_API = `https://ws.jeancalvin.fr`
export const URL_IMAGE_PAR_DEFAUT = "https://storage.jeancalvin.fr/full/livre-defaut.jpg"
export const LIEN_IMAGE_THUMB = "https://storage.jeancalvin.fr/full"
//profil prod ou dev ou venteDisabled
export const PROFIL = "prod"  // si vente disabled, alors la vente est désactivée sur le site
export const PROFIL_LOCAL = false

// BASKET ----------------------------------------------------
export const URL_BASE_BASKET = URL_API
// export const URL_BASE_BASKET = `http://localhost:8086`
// -----------------------------------------------------------

// MEDIAS ----------------------------------------------------
export const URL_BASE_MEDIAS = URL_API
// -----------------------------------------------------------

// GLC ----------------------------------------------------
export const URL_BASE_GLC = URL_API
// export const URL_BASE_GLC = `http://localhost:8082`
// -----------------------------------------------------------

// USER ----------------------------------------------------
export const URL_BASE_USER = URL_API
// export const URL_BASE_USER = `http://localhost:8085`
// -----------------------------------------------------------

// DELIVERY ----------------------------------------------------
export const URL_BASE_DELIVERY = URL_API
// export const URL_BASE_DELIVERY = `http://localhost:8090`
// -----------------------------------------------------------

// ORDER ----------------------------------------------------
export const URL_BASE_ORDER = URL_API
// export const URL_BASE_ORDER = `http://localhost:8088`
// -----------------------------------------------------------


export const URL_WEBSITE = PROFIL_LOCAL ? "http://localhost:3000" : "https://www.librairiecalvin.fr"

//**************************************************************************** */
// config openidconnect
export const OIDC_CONFIG = {
    authority: "https://iam.jeancalvin.fr/realms/ljc",
    client_id: "ljc-website",
    automaticSilentRenew: true,
    redirect_uri: URL_WEBSITE + '/callback',
    silent_redirect_uri: URL_WEBSITE + '/callback'
};
//**************************************************************************** */

export function getToken(auth) {
    auth.signinSilent();
    const oidcStorage = sessionStorage.getItem(`oidc.user:https://iam.jeancalvin.fr/realms/ljc:ljc-website`)
    if (!oidcStorage) {
        return "";
    }
    const user = User.fromStorageString(oidcStorage);
    return user ? `Bearer ${user?.access_token}` : "";
};

//----------------------------------------------------------------------------------------------------------
// cle prod
export const cle_stripe_ljc_publique = "pk_live_51Htb74E8tS6wz08wXk49fOzzuxEmnioN6fxHKAfF9Zh9IQ5QwssKyC38Achy74yQswK23owKIvsuoG0ajdfDJVxq00lRMxRu2W";

// cle test
// export const cle_stripe_ljc_publique = "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";
//----------------------------------------------------------------------------------------------------------
